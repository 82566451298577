@font-face {
    font-family: 'Lato';
    src: url('./asset/font/Lato/Lato-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Lato';
    src: url('./asset/font/Lato/Lato-Light.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Lato';
    src: url('./asset/font/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
}

* {
    box-sizing: border-box;
}

html {
    scrollbar-gutter: stable;
}

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
    line-height: 20px;
    color: #67768a;
    min-width: 1280px;
}
